import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import './styles/main.css';

import Index from './components/Index';
import Log from './components/Log';

function App() {
	return (
		<Router>
			<Switch>
				<Route exact path="/" component={Index} />
				<Route exact path={'/:uuid'} component={Log}/>
			</Switch>
		</Router>
	);
}

export default App;