import TopBarProgress from "react-topbar-progress-indicator";
import { useHistory } from "react-router-dom";
import React, { useState } from 'react';
import { Helmet } from 'react-helmet'
import axios from 'axios';

TopBarProgress.config({
    barColors: {
      "0": "#059669",
      "1.0": "#059669"
    },
    shadowBlur: 0,
    shadowColor: "#005eff00",
    barThickness: 3
});

export default function Index() {
    const [input, setInput] = useState();
    const [loading = false, setLoading] = useState();
    const [error, setError] = useState();
    const history = useHistory();

    return (
        <>
            <Helmet>
                <title>KUBBUR Ticket Viewer</title>
            </Helmet>
            <div className="logo">
                <img src="/kubbur.svg" height="50" alt="KUBBUR Logo" />
            </div>
            <div className="h-full">
                <div className="main_page">
                    <div className="search_container">
                        <div>
                            <div className="title">
                                <h1>Ticket Logs Viewer</h1>
                            </div>
                            { (error && error !== "") &&
                            <div className="notification">
                                <span>{error}</span>
                            </div>}
                            <div className="form">
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    setLoading(true);
                                    axios.get(`https://api.tickets.kubbur.com/api/v1/get/${input}`)
                                        .then(res => {
                                            setLoading(false);
                                            const data = res.data;
                                            if (data.status && data.status.code && data.status.code === 404) {
                                                setError("The ticket could not be found.");
                                            }
                                            else {
                                                history.push(`/${data.information["ticket-id"]}`);
                                            }
                                        })
                                }}>
                                    <input type="text" placeholder="Enter ticket ID" onKeyUp={(e) => {
                                        setInput(e.target.value);
                                        setError(null);
                                    }} />
                                    <button type="submit">View</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { loading &&
                <TopBarProgress />
            }
        </>
    );
}
